import { APITypes } from "@lib/api.types";
import { Block, Svg } from "components";

interface CaseInformationBlockProps {
  block: APITypes.Components.ContentBlocks.CaseInformationBlock;
}

const CaseInformationBlock = ({ block }: CaseInformationBlockProps) => {
  const classes = (color: string) =>
    [
      "grid",
      "gap-4",
      "p-6",
      "items-center",
      "justify-items-left",
      "rounded-2xl",
      "font-normal",
      "text-base",
      "sm:text-2xl",
      "bg-greyCold",
      "justify-left",
      "h-fit",
    ]
      .filter(Boolean)
      .join(" ");

  return (
    <Block>
      <div
        className={`grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-4 lg:gap-10 md:mt-10`}
      >
        <div className={classes("")}>
          {block.case_information_card.title_with_logo.map((card) => {
            return (
              <div
                key={card.id}
                className="flex items-center text-center gap-4"
              >
                <Svg className="w-5 h-10" icon={card.logo} title={card.title} />
                {card.title}
              </div>
            );
          })}
        </div>
        <div className="grid text-base sm:text-2xl font-light">
          {block.description}
        </div>
      </div>
    </Block>
  );
};

export default CaseInformationBlock;
