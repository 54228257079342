import TagBlock from "@components/ContentBuilder/blocks/TagBlock";
import { APITypes } from "@lib/api.types";
import { Image } from "components";
import Link from "next/link";
import React from "react";

interface CaseTeaserProps {
  // logo?: any;
  name: string;
  image: APITypes.Components.Media;
  description: string;
  url: string;
  backgroundColor: string;
  textColor: string;
  tags: APITypes.Components.ContentBlocks.Tag[];
  title?: string;
}

const CaseTeaser = ({
  // logo,
  image,
  name,
  description,
  url,
  backgroundColor,
  textColor,
  tags,
  title,
}: CaseTeaserProps) => {
  return (
    <Link href={url} className="group flex flex-col min-h-full">
      <div
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
          borderColor: textColor,
        }}
        className="rounded-2xl flex flex-col h-full"
      >
        <div className="h-auto md:h-fit-content lg:h-fit-content">
          <Image
            className="rounded-2xl"
            image={image}
            alt={image.name}
            style={{ maxHeight: "472px" }}
          />
        </div>
        <div
          className={`
            max-[320px]:px-1 px-6 lg:px-14 pt-14 pb-14 flex flex-col flex-1
          `}
        >
          <div className="flex font-bold text-xl items-center pb-2 | group-hover:text-brand-red | transition-colors flex-1">
            {/* {logo && (
              <Image
                className="h-10 w-10 pr-3"
                image={logo}
                alt={`logo ${name}`}
              />
            )}{" "} */}
            {name.toUpperCase()}
          </div>
          <div className="text-3xl font-bold">
            {title && title.toUpperCase()}
          </div>
          <div className="flex-grow mt-4 text-base font-normal ">
            {description}
          </div>
          <div className="mt-4 flex items-center space-x-2">
            <TagBlock block={tags} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export { CaseTeaser };
