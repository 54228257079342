import { APITypes } from "@lib/api.types";
import { Image } from "components";
import Link from "next/link";
import React from "react";

interface CardTeaserProps {
  name: string;
  title: string;
  image: APITypes.Components.Media;
  url?: string;
}

const CardTeaser = ({ image, name, title, url }: CardTeaserProps) => {
  const hoverEffect = url != null ? "hover:bg-purple outlineCustom" : "";

  const content = (
    <div className={`rounded-2xl bg-greyCold ${hoverEffect}`}>
      <Image className="rounded-t-2xl" image={image} alt={image.name} />
      <div className="px-8 py-8">
        <div className="font-heroTitle sm:text-2xl font-black">{name}</div>
        <div className="sm:text-2xl font-light italic">{title}</div>
      </div>
    </div>
  );

  return url != null ? <Link href={url}>{content}</Link> : content;
};

export { CardTeaser };
