import Footer from "components/Footer";
import { DesktopNavigation } from "components/Navigation";
import { IWithChildren } from "lib/interfaces";
import { TFunction } from "next-i18next";
import { useRouter } from "next-translate-routes";
import { useEffect } from "react";
import Meta from "../Meta";

interface LayoutProps extends IWithChildren {
  pageTitle: string;
  translate: TFunction;
  slug?: string;
}

const Layout = ({ children, translate, pageTitle, slug }: LayoutProps) => {
  const { asPath } = useRouter();
  const isHomePage = asPath === "/";

  useEffect(() => {
    const loadSvgSprite = async () => {
      const svgSpriteContainer = document.querySelector(
        "[data-svg-sprite-container]"
      );

      if (svgSpriteContainer) {
        const svgSprite = await fetch("/svg-sprite.svg");

        svgSpriteContainer.innerHTML = await svgSprite.text();
        // @ts-ignore:
        svgSpriteContainer.style.display = "none";
      }
    };

    loadSvgSprite().then();
  }, []);

  return (
    <div className="font-body font-bold text-lg bg-black text-white">
      <Meta title={pageTitle} />
      <div className="min-h-screen">
        <noscript>
          <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-NPN3LQH"
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          ></iframe>
        </noscript>

        <DesktopNavigation translate={translate} showInfoBanner={isHomePage} />
        <main>{children}</main>
      </div>
      <Footer translate={translate} slug={slug} />
      <div data-svg-sprite-container="" />
    </div>
  );
};

export { Layout };
