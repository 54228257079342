import { APITypes } from "@lib/api.types";
import { Block, Image, SectionSeparator } from "components";

interface IImagesProps {
  block: APITypes.Components.ContentBlocks.Images;
}

const Images = ({ block }: IImagesProps) => {
  return (
    <Block>
      <div className={`grid md:grid-cols-3 justify-center items-center mx-4`}>
        {block.images.data.map((img, i) => {
          return (
            <div
              key={i}
              className={`relative ${
                i === 0 && "z-30 left-[-30px] top-[20px] md:left-[0px]"
              } ${
                i === 1 &&
                "z-20 left-[0px] top-[0px] md:left-[-20px] md:top-[100px]"
              } ${
                i === 2 &&
                "z-10 left-[30px] top-[-20px] md:left-[-40px] md:top-[140px]"
              }`}
            >
              <Image image={img.attributes} alt={img.attributes.name} />
            </div>
          );
        })}
      </div>
      <SectionSeparator />
    </Block>
  );
};

export default Images;
