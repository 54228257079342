import {
  availableLanguages,
  DEFAULT_LANGUAGE,
  SupportedLocales,
} from "@lib/localization";
import { NEXT_LOCALE_COOKIE, Paths } from "@lib/routing";
import { getLocalizedPath, getPathFromString } from "@lib/routing/routing.util";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import Cookies from "js-cookie";
import { Listbox } from "@headlessui/react";
import { Svg } from "..";

const setNextLocaleCookieTo = (locale: SupportedLocales) => {
  const existingCookie = Cookies.get(NEXT_LOCALE_COOKIE);
  if (existingCookie) {
    console.log("existingCookie", existingCookie);
  }
  Cookies.set(NEXT_LOCALE_COOKIE, locale, { SameSite: "None", secure: true });
};

type LanguageSwitcherProps = {
  slug: string | undefined;
};

const LanguageSwitcher = ({ slug }: LanguageSwitcherProps) => {
  const router = useRouter();

  const activeLanguage = useMemo(() => {
    const l = availableLanguages.find(
      (language) => language.value === router.locale
    );
    if (!l) return DEFAULT_LANGUAGE;
    return l;
  }, [router]);

  const onSelectOtherLanguage = useCallback(
    (language: string) => {
      const l = availableLanguages.find((al) => al.value === language);
      if (l) {
        const { value: chosenLocale } = l;
        const pathParts = router.asPath.split("/");
        const pathString = pathParts[1];
        const subroutes = pathParts.slice(1, pathParts.length - 2);

        const p = subroutes.length === 0 ? pathString : subroutes[0];

        const path = getPathFromString(
          `/${p}`,
          router.locale as SupportedLocales
        );
        const localizedPath = getLocalizedPath(
          path ?? Paths.Home,
          chosenLocale
        );

        const updatedPath =
          slug != null ? `${localizedPath}/${slug}` : localizedPath;
        setNextLocaleCookieTo(chosenLocale);
        router.push(updatedPath, updatedPath, {
          locale: chosenLocale,
        });
      }
    },
    [slug, router]
  );

  return (
    <Listbox
      className="relative"
      value={router.locale}
      onChange={onSelectOtherLanguage}
      as="div"
    >
      <Listbox.Button className="flex items-center gap-1">
        {availableLanguages.find(
          (language) => language.value === activeLanguage?.value
        )?.label ?? DEFAULT_LANGUAGE.label}
        <Svg className={`w-4 h-4`} icon="arrow-up" title="arrow-up" />
      </Listbox.Button>
      <Listbox.Options className="absolute top-0 -left-10 | grid gap-2 | px-10 bg-black">
        {availableLanguages.map((language) => (
          <Listbox.Option
            className="flex items-center | gap-2 cursor-pointer"
            value={language.value}
            key={language.value}
          >
            <span
              className={
                language.value === activeLanguage?.value ? "font-bold" : ""
              }
            >
              {language.label}
            </span>
          </Listbox.Option>
        ))}
      </Listbox.Options>
    </Listbox>
  );
};

export { LanguageSwitcher };
