import { APITypes } from "@lib/api.types";
import { Block, Image, Svg } from "components";

interface CaseQuoteBlockProps {
  block: APITypes.Components.ContentBlocks.CaseQuoteBlock;
}

const CaseQuoteBlock = ({ block }: CaseQuoteBlockProps) => {
  const classes = () =>
    [
      "grid",
      "col-span-4",
      "md:col-span-3",
      "gap-4",
      "p-8",
      "items-center",
      "justify-items-end",
      "rounded-2xl",
      "font-normal",
      "text-2xl",
      "justify-left",
    ]
      .filter(Boolean)
      .join(" ");

  return (
    <Block>
      <div
        className={`grid grid-cols-1 md:grid-cols-4 gap-4 md:gap-6 lg:gap-6`}
      >
        <div className="grid items-center justify-items-center col-span-4 md:col-span-1">
          <Image
            style={{
              borderRadius: "16px",
              objectFit: "cover",
              width: "100%",
              height: "100%",
            }}
            image={block.image.data.attributes}
            alt={block.image.data.attributes.name}
          />
        </div>
        <div
          className={classes()}
          style={{
            backgroundColor: block.hex_background_color,
          }}
        >
          <div className="font-bold text-2xl md:text-3xl text-black">
            {block.quote}
          </div>
          <div className="flex items-end justify-end font-semibold text-base md:text-2xl text-black">
            {block.author}
          </div>
        </div>
        <div
          className={`grid col-span-4 bg-${block.button.color} rounded-2xl  text-black`}
        >
          <a
            href={block.button.external_link}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center gap-4 text-base text-2xl md:text-3xl font-bold p-10"
          >
            {block.button.text}{" "}
            <Svg
              className="h-4 w-4"
              icon={"arrow-right"}
              title={"arrow-right"}
            />
          </a>
        </div>
      </div>
    </Block>
  );
};

export default CaseQuoteBlock;
