import { APITypes } from "@lib/api.types";
import { DEFAULT_LANGUAGE, SupportedLocales } from "@lib/localization";
import { Paths } from "@lib/routing";
import { getLocalizedPath } from "@lib/routing/routing.util";
import { Block, CaseTeaser, TeaserGrid } from "components";
import { useRouter } from "next/router";

interface CaseBlockProps {
  block: APITypes.Components.ContentBlocks.CaseBlock;
}

const CaseBlock = ({ block }: CaseBlockProps) => {
  const { locale } = useRouter();

  return (
    <Block>
      <TeaserGrid gap="sm" columns={2}>
        {block.FeaturedCases.cases.data
          .slice(0, 2)
          .map(({ attributes, id }) => (
            <CaseTeaser
              url={`${getLocalizedPath(
                Paths.Work,
                (locale ?? DEFAULT_LANGUAGE) as SupportedLocales
              )}/${attributes.slug}`}
              name={attributes.name}
              description={attributes.short_description}
              image={attributes.images.data[0].attributes}
              key={id}
              backgroundColor={attributes.hex_background_color}
              textColor={attributes.text_color}
              tags={attributes.tags}
              title={attributes.title}
            />
          ))}
      </TeaserGrid>
    </Block>
  );
};

export default CaseBlock;
