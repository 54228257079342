import { DEFAULT_LANGUAGE, SupportedLocales } from "@lib/localization";
import { Paths } from "@lib/routing";
import { getLocalizedPath } from "@lib/routing/routing.util";
import { Image, LanguageSwitcher } from "components";
import API from "lib/fetchData";
import { IWithChildren } from "lib/interfaces";
import { TFunction } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import useGetGlobals from "./useGetGlobals";

const generateMenuItem = (title: string, path: string) => (
  <Link href={path} key={path}>
    {title}
  </Link>
);

export interface FooterProps extends IWithChildren {
  translate: TFunction;
  slug: string | undefined;
}

export default function Footer({ translate: t, slug, children }: FooterProps) {
  const { locale } = useRouter();
  const l = (locale ?? DEFAULT_LANGUAGE) as SupportedLocales;
  const { globalData } = useGetGlobals(l);
  const [isFigmaPageExist, setIsFigmaPageExist] = useState(false);

  useEffect(() => {
    const fetchFigmaCoursesPage = async () => {
      const api = new API(l);
      const { data: page } = await api.fetchFigmaCoursesPage();

      if (page != null) {
        setIsFigmaPageExist(true);
      }
    };

    fetchFigmaCoursesPage();
  }, [l, locale]);

  if (globalData == null) {
    return <></>;
  }

  const content: {
    title: string;
    links: JSX.Element[];
    subGroup?: { title: string; links: JSX.Element[] };
  }[] = [
    {
      title: t("company"),
      links: [
        generateMenuItem(t("pages.work"), getLocalizedPath(Paths.Work, l)),
        generateMenuItem(
          t("pages.aboutUs"),
          getLocalizedPath(Paths.AboutUs, l)
        ),
        generateMenuItem(
          t("pages.services"),
          getLocalizedPath(Paths.Services, l)
        ),
      ],
    },
    {
      title: t("Contact"),
      links: [
        generateMenuItem(
          globalData.default_email_address,
          `mailto:${globalData.default_email_address}`
        ),
        generateMenuItem(
          globalData.default_address,
          globalData.default_address_link
        ),
        generateMenuItem(globalData.company_name, "/"),
      ],
    },
    {
      title: t("language"),
      links: [<LanguageSwitcher slug={slug} key={1} />],
      // subGroup: {
      //   title: t("GDPR"),
      //   links: [
      //     generateMenuItem(
      //       t("pages.privacyPolicy"),
      //       getLocalizedPath(Paths.PrivacyPolicy, l)
      //     ),
      //     generateMenuItem(
      //       t("pages.cookiePolicy"),
      //       getLocalizedPath(Paths.CookiePolicy, l)
      //     ),
      //     generateMenuItem(
      //       t("pages.manageCookies"),
      //       getLocalizedPath(Paths.ManageCookies, l)
      //     ),
      //   ],
      // },
    },
  ];

  if (isFigmaPageExist) {
    content[0].links.push(
      generateMenuItem(
        t("pages.figmaCourses"),
        getLocalizedPath(Paths.FigmaCourses, l)
      )
    );
  }

  return (
    <footer className="footer-container md:flex md:justify-between text-base font-normal mx-12 space-y-4 md:space-y-0 my-6">
      {/* company */}
      {globalData.logo && (
        <div className="grid grid-cols-1 my-2 max-w-[200px]">
          <Image
            image={globalData.logo.data.attributes}
            alt={globalData.logo.data.attributes.name}
          />
          <div className="mt-2">{globalData.company_description}</div>
        </div>
      )}
      {/* menu */}
      {content.map((group, i) => {
        return (
          <div key={i} className="flex-col max-w-[200px] my-2">
            <h3 className="font-bold">{group.title}</h3>
            <div className="grid col-1">{group.links.map((link) => link)}</div>
            {group.subGroup != null && (
              <div key={i} className="grid grid-cols-1 gap-2 max-w-[200px]">
                <h3 className="font-bold">{group.subGroup.title}</h3>
                {group.subGroup.links}
                {/* {group.subGroup.links.map((link) => link)} */}
              </div>
            )}
          </div>
        );
      })}
    </footer>
  );
}
