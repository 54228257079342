import { APITypes } from "@lib/api.types";
import { Svg } from "components/Svg";
import { IWithChildren } from "lib/interfaces";
import Link from "next/link";
import React from "react";

interface ButtonProps extends IWithChildren {
  href?: string;
  icon?: string;
  size: "slim" | "small" | "large";
  color: APITypes.Components.ButtonColors;
  fill: boolean;
  disabled?: boolean;
  as?: React.ElementType;
  isFormSubmit?: Boolean;
  onClick?: () => void;
  isExternalLink?: boolean;
  scroll?: boolean;
  fullWidth?: boolean;
}

const Button = ({
  children,
  href = "",
  icon,
  size,
  disabled,
  color,
  fill,
  as,
  onClick,
  isFormSubmit = false,
  isExternalLink,
  scroll,
  fullWidth,
}: ButtonProps) => {
  //TODO check style for sizes SVG
  const isSlim = size === "slim";
  const isSmall = size === "small";
  const isLarge = size === "large";

  const classes = [
    fullWidth && "w-full",
    "text-base",
    "inline-flex items-center gap-4",
    "border-2",
    "rounded-large",
    "pt-5",
    "px-[24px]",
    "py-[16px]",
    "shadow-[0px_2px_0px_#FFFFFF]",
    "font-black",
    isSlim && "h-[30px] py-3.5 pt-4 min-w-[20px] justify-center",
    isSmall && "h-[48px] min-w-[20px] justify-center",
    isLarge && "justify-center",
    isLarge && "min-w-full h-14 px-10 md:min-w-0 lg:h-20",
    isLarge && "text-lg lg:text-2xl",
    "active:translate-y-[0.1rem] active:shadow-none",
    "hover:scale-105 transition-transform hover:ease-in-out duration-300 hover:drop-shadow-md",
    color === "orange" &&
      (fill
        ? "text-white bg-orange"
        : "text-orange bg-transparent shadow-orange border-orange"),
    color === "purple" &&
      (fill
        ? "text-white bg-purple "
        : "text-purple bg-transparent shadow-purple border-purple"),
    color === "black" && "border-black shadow-black",
    disabled &&
      "text-brand-placeholder border-brand-placeholder shadow-brand-placeholder pointer-events-none",
  ]
    .filter(Boolean)
    .join(" ");

  const content = (
    <React.Fragment>
      <div className="pb-1">{children}</div>
      {isSmall && icon && <Svg className="w-3 h-3" icon={icon} title="" />}
      {isLarge && icon && (
        <Svg className="w-4 h-4 lg:w-6 lg:h-6" icon={icon} title="" />
      )}
    </React.Fragment>
  );

  if (as) {
    const Component = as;
    if (isFormSubmit) {
      return (
        <Component className={classes} type="submit">
          {content}
        </Component>
      );
    }

    return (
      <Component onClick={onClick} className={classes}>
        {content}
      </Component>
    );
  }
  const props = {
    target: isExternalLink ? "_blank" : undefined,
    rel: isExternalLink ? "noopener noreferrer" : undefined,
  };

  const link = isExternalLink ? (
    <a {...props} href={href} className={classes}>
      {content}
    </a>
  ) : (
    <Link {...props} href={href} passHref scroll={scroll} className={classes}>
      {content}
    </Link>
  );

  return link;
};

export { Button };
