import React from "react";
import { IWithChildren } from "lib/interfaces";

export interface ContainerProps extends IWithChildren {
  className?: string;
  padding?: keyof {
    x: "x";
    y: "y";
    xy: "xy";
  };
}

const Container = ({ className, padding = "xy", children }: ContainerProps) => {
  const classes = [
    className ?? "",
    padding === "x" ? "px-6 sm:px-10" : "",
    padding === "y" ? "py-12 sm:py-[220px]" : "",
    padding === "xy" ? "px-6 py-0 sm:px-10 sm:py-12 sm:pt-16 pb-12" : "",
  ]
    .filter(Boolean)
    .join(" ");

  return <div className={classes}>{children}</div>;
};

export { Container };
