import { APITypes } from "@lib/api.types";
import { Block } from "components";

import IllustrationBlock from "./IllustrationBlock";

interface IIllustrationBlocksProps {
  block: APITypes.Components.ContentBlocks.IllustrationBlocks;
}

const IIllustrationBlocks = ({ block }: IIllustrationBlocksProps) => {
  return (
    <Block>
      <div
        id="fool"
        className={`grid grid-cols-1 items-center snap vertical on`}
      >
        {block.illustration_blocks.map((block) => {
          return <IllustrationBlock key={block.id} block={block} />;
        })}
      </div>
    </Block>
  );
};

export default IIllustrationBlocks;
