import TagBlock from "@components/ContentBuilder/blocks/TagBlock";
import { APITypes } from "lib/api.types";
import React from "react";

import { SanitizeString } from "..";
import { ButtonWrapper } from "../Button";
import { Image } from "../Image";

export interface HeroProps {
  hero: APITypes.Components.Hero;
  alignHero: "center" | "left";
}

export type IHero = Omit<APITypes.Components.Hero, "id">;

const Hero = ({ hero, alignHero }: HeroProps) => {
  const { title, button, background_image, name, logo, tags, description } =
    hero;

  const alignment =
    alignHero === "center"
      ? "sm:justify-center text-left"
      : "align-start lg:pl-6 px-2 text-center lg:text-left";

  return (
    <React.Fragment>
      <div className="relative | max-w-[1260px] | mx-auto px-0 sm:px-2 mt-32 flex align-center">
        <header
          className={`relative max-w-[1260px] flex ${
            alignHero === "center" ? "mx-auto" : ""
          }`}
        >
          <div className={`flex flex-col justify-center ${alignment}`}>
            <div
              className={`flex justify-center ${alignment} lg:pt-4 pb-8 lg:p-18`}
            >
              <h1 className="font-heroTitle font-dark | text-smHero max-[320px]:text-3xl  sm:text-5xl md:text-7xl lg:text-lgHero xl:text-xlHero">
                <SanitizeString text={title} />
              </h1>
              {background_image != null && background_image.data != null ? (
                <Image
                  className="absolute z-10 md:hidden w-[260px] mt-[-22px] max-[320px]:mt-[-80px]"
                  image={background_image.data[1].attributes}
                  alt={title}
                />
              ) : null}
              {background_image != null && background_image.data != null ? (
                <Image
                  className="absolute z-10 hidden md:block pl-16 h-auto | lg:w-[900px] lg:pt-[30px] lg:pl-[40px] | xl:pt-[40px] xl:pl-[80px] xl:w-[1100px]"
                  image={background_image.data[0].attributes}
                  alt={title}
                />
              ) : null}
            </div>
            {description != null && (
              <div className="flex ">
                <div
                  className={`flex-0 sm:w-[780px] text-center sm:text-left text-1xl sm:text-2xl font-light ${alignment}`}
                >
                  {description}
                </div>
              </div>
            )}
            {button != null && button.length > 0 && (
              <div
                className={`flex justify-center ${
                  alignHero === "left" ? "lg:justify-start lg:pl-6" : ""
                }`}
              >
                <div className={`flex sm:flex-row flex-col mt-20 gap-4 w-fit`}>
                  {button.map((btn, i) => (
                    <div key={i} className={`flex justify-center items-center`}>
                      <ButtonWrapper
                        button={btn}
                        size="small"
                        fill={i % 2 === 0}
                        fullWidth={true}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}

            {name != null && logo != null && tags ? (
              <div className="pb-20">
                <div
                  className={`flex items-center justify-center ${alignment}  font-bold max-[320px]:text-2xl sm:text-4xl gap-4 mt-10`}
                >
                  <Image
                    className="w-10"
                    image={logo.data.attributes}
                    alt={title}
                  />
                  {name.toUpperCase()}
                </div>
                <div
                  className={`flex justify-center ${alignment} mt-8`}
                  style={{ color: "white", borderColor: "white" }}
                >
                  <TagBlock block={tags} />
                </div>
              </div>
            ) : null}
          </div>
        </header>
      </div>
    </React.Fragment>
  );
};

export { Hero };
