import { APITypes } from "lib/api.types";
import { SectionTitle, Image, Svg } from "components";

interface ContactProps {
  contact: APITypes.Components.Contact;
}

function Contact({ contact }: ContactProps) {
  return (
    <div className="py-12 pb-0 relative">
      <div>
        <SectionTitle className={"text-3xl sm:text-5xl text-center"}>
          {contact.title.toUpperCase()}
        </SectionTitle>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6 md:gap-4 lg:gap-4 md:mt-14">
          <div className="grid justify-center mr-1">
            <Image
              style={{ borderRadius: "16px" }}
              image={{
                ...contact.image.data.attributes,
              }}
              alt={contact.title}
            />
          </div>
          <div className="grid grid-cols-4 md:grid-cols-2 gap-4">
            {contact.social_cards.map((card, i) => (
              <a
                target="_blank"
                rel="noopener noreferrer"
                key={i}
                href={card.link}
                className="group flex flex-col items-center justify-center rounded-2xl bg-greyCold text-purple text-2xl transition ease-in-out hover:text-white hover:bg-purple transition-colors pt-4 pb-4"
              >
                <Svg
                  className="h-8 md:mt-6 grid"
                  icon={card.icon}
                  title={card.title}
                />
                <div className="grid h-8 hidden md:block group-hover:hidden" />
                <div className="grid text-base font-normal mt-2 hidden group-hover:hidden md:group-hover:block">
                  {card.title}
                </div>
              </a>
            ))}
          </div>
        </div>
        <div className="grid gap-2 md:grid-cols-2 lg:gap-4 mt-4 text-2xl sm:text-3xl font-extrabold">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={contact.left_card.link}
            className="rounded-2xl bg-greyCold "
          >
            <div className="grid grid-cols-5 gap-2 items-center h-32 pl-2 sm:pl-8">
              <Svg
                className="h-14"
                icon={contact.left_card.icon}
                title={contact.left_card.title}
              />
              <div className="grid col-span-3">
                <div className="">{contact.left_card.title.toUpperCase()}</div>
                <div className="text-base font-normal">
                  {contact.left_card.description}
                </div>
              </div>
            </div>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={contact.right_card.link}
            className="rounded-2xl bg-greyCold"
          >
            <div className="grid grid-cols-5 gap-2 items-center h-32 pl-2 sm:pl-8">
              <Svg
                className="h-14"
                icon={contact.right_card.icon}
                title={contact.right_card.title}
              />
              <div className="grid col-span-4">
                <div className="">{contact.right_card.title.toUpperCase()}</div>
                <div className="text-base font-normal">
                  {contact.right_card.description}
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export { Contact };
