import { APITypes } from "@lib/api.types";
import { Block, Image, ButtonWrapper } from "components";
import TextBlock from "./TextBlock";

interface IllustrationBlockProps {
  block: APITypes.Components.ContentBlocks.IllustrationBlock;
}

const IllustrationBlock = ({ block }: IllustrationBlockProps) => {
  return (
    <Block>
      <div
        className={`grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 lg:gap-16 items-center`}
      >
        <div className={`${block.position === "right" && "md:order-2"}  `}>
          <Image
            image={{
              ...block.illustration.data.attributes,
            }}
            alt={block.text_block.title}
          />
        </div>
        <div className="md:order-1">
          <TextBlock block={block.text_block} />
          {block.text_block.button && (
            <div className="pt-6 grid justify-center md:justify-start">
              <ButtonWrapper
                button={block.text_block.button}
                size="small"
                fill={false}
              />
            </div>
          )}
        </div>
      </div>
    </Block>
  );
};

export default IllustrationBlock;
