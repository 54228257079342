import { Image, SanitizeString } from "components";
import { APITypes } from "lib/api.types";
import React from "react";

interface HeroCardProps {
  text: string;
  backgroundColor: string;
  width: string;
  logo?: APITypes.StrapiData<APITypes.Components.Media>[] | undefined;
}

const HeroCard = ({ text, backgroundColor, logo, width }: HeroCardProps) => {
  const content = (
    <React.Fragment>
      <div
        className={`
          flex
          grid-cols-1
          ${width === "half1" && "md:col-span-1"}
          ${width === "half2" && "md:col-span-1"}
          ${width === "full" && "md:col-span-7"} 
          ${width === "p60" && "md:col-span-4"} 
          ${width === "p40" && "md:col-span-3"}
          px-6 max-[320px]:px-1 py-8 sm:px-6 |
          rounded-2xl
          ${backgroundColor === "lightOrange" && "bg-lightOrange"}
          ${backgroundColor === "lightPurple" && "bg-lightPurple"}
          ${backgroundColor === "wheat" && "bg-wheat"}
          content-center
          justify-center
          items-center     
        `}
      >
        <div className="text-3xl text-black md:text-4xl lg:text-5xl [&>purple]:text-white">
          <div
            className={`${
              logo &&
              "grid grid-cols-1 md:grid-cols-2 gap-10 px-4 pt-8 pb-8 justify-center items-center"
            }`}
          >
            <SanitizeString text={text} />
            {logo && (
              <div className="flex flex-wrap gap-16 justify-center items-center">
                {logo
                  .sort(
                    (a, b) =>
                      new Date(b.attributes.updatedAt).valueOf() -
                      new Date(a.attributes.updatedAt).valueOf()
                  )
                  .map((img, i) => (
                    <div key={i}>
                      <div className="mx-auto items-center justify-center max-w-[90px] sm:max-w-none">
                        <Image
                          style={{
                            objectFit: "contain",
                            maxHeight: "28px",
                          }}
                          image={img.attributes}
                          alt={img.attributes.name ?? ""}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return content;
};

export { HeroCard };
