import { APITypes } from "lib/api.types";

import CaseBlock from "./blocks/CaseBlock";
import CaseInformationBlock from "./blocks/CaseInformationBlock";
import CaseNavigationBlock from "./blocks/CaseNavigationBlock";
import CaseQuoteBlock from "./blocks/CaseQuoteBlock";
import FeaturedFigmaTrainingBlock from "./blocks/FeaturedFigmaTrainingBlock";
import FeaturedTeamBlock from "./blocks/FeaturedTeamBlock";
import FullWidthButtonBlock from "./blocks/FullWidthButtonBlock";
import IconCardBlock from "./blocks/IconCardsBlock";
import IllustrationBlock from "./blocks/IllustrationBlock";
import IllustrationBlocks from "./blocks/IllustrationBlocks";
import ImageBlock from "./blocks/ImageBlock";
import Images from "./blocks/Images";
import RichtextBlock from "./blocks/RichtextBlock";
import SingleButtonBlock from "./blocks/SingleButtonBlock";
import SocialButtonsBlock from "./blocks/SocialButtonsBlock";
import TextBlocks from "./blocks/TextBlocks";
import TextWithImageBlock from "./blocks/TextWithImageBlock";
import TitleBlock from "./blocks/TitleBlock";
import VideoBlock from "./blocks/VideoBlock";

interface IContentBuilderProps {
  contentBlocks: APITypes.Components.ContentBlocks[];
}

const ContentBuilder = ({ contentBlocks }: IContentBuilderProps) => {
  const getComponent = (
    component: APITypes.Components.ContentBlocks,
    i: number
  ) => {
    let c: APITypes.Components.ContentBlocks;

    switch (component.__component) {
      case APITypes.Components.ContentBlocks.Types.Title:
        c = component as APITypes.Components.ContentBlocks.Title;
        return <TitleBlock key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.TextWithImage:
        c = component as APITypes.Components.ContentBlocks.TextWithImage;
        return (
          <TextWithImageBlock
            key={i}
            block={c as APITypes.Components.ContentBlocks.TextWithImage}
          />
        );
      case APITypes.Components.ContentBlocks.Types.Button:
        c = component as APITypes.Components.ContentBlocks.Button;
        return <SingleButtonBlock key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.IconCards:
        c = component as APITypes.Components.ContentBlocks.IconCards;
        return (
          <IconCardBlock
            key={i}
            block={c as APITypes.Components.ContentBlocks.IconCards}
          />
        );
      case APITypes.Components.ContentBlocks.Types.IllustrationBlock:
        c = component as APITypes.Components.ContentBlocks.IllustrationBlock;
        return (
          <IllustrationBlock
            key={i}
            block={c as APITypes.Components.ContentBlocks.IllustrationBlock}
          />
        );
      case APITypes.Components.ContentBlocks.Types.Images:
        c = component as APITypes.Components.ContentBlocks.Images;
        return <Images key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.TextBlocks:
        c = component as APITypes.Components.ContentBlocks.TextBlocks;
        return <TextBlocks key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.IllustrationBlocks:
        c = component as APITypes.Components.ContentBlocks.IllustrationBlocks;
        return (
          <IllustrationBlocks
            key={i}
            block={c as APITypes.Components.ContentBlocks.IllustrationBlocks}
          />
        );
      case APITypes.Components.ContentBlocks.Types.CaseInformationBlock:
        c = component as APITypes.Components.ContentBlocks.CaseInformationBlock;
        return <CaseInformationBlock key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.CaseNavigationBlock:
        c = component as APITypes.Components.ContentBlocks.CaseNavigationBlock;
        return <CaseNavigationBlock key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.CaseQuoteBlock:
        c = component as APITypes.Components.ContentBlocks.CaseQuoteBlock;
        return (
          <CaseQuoteBlock
            key={i}
            block={c as APITypes.Components.ContentBlocks.CaseQuoteBlock}
          />
        );
      case APITypes.Components.ContentBlocks.Types.CaseBlock:
        c = component as APITypes.Components.ContentBlocks.CaseBlock;
        return <CaseBlock key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.FeaturedTeam:
        c = component as APITypes.Components.ContentBlocks.FeaturedTeam;
        return <FeaturedTeamBlock key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.SocialButtons:
        c = component as APITypes.Components.ContentBlocks.SocialButtons;
        return <SocialButtonsBlock key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.ImageBlock:
        c = component as APITypes.Components.ContentBlocks.ImageBlock;
        return <ImageBlock key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.VideoBlock:
        c = component as APITypes.Components.ContentBlocks.VideoBlock;
        return <VideoBlock key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.RichtextBlock:
        c = component as APITypes.Components.ContentBlocks.RichtextBlock;
        return <RichtextBlock key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.FeaturedFigmaTrainings:
        c =
          component as APITypes.Components.ContentBlocks.FeaturedFigmaTrainings;
        return <FeaturedFigmaTrainingBlock key={i} block={c} />;
      case APITypes.Components.ContentBlocks.Types.FullWidthButtonBlock:
        c = component as APITypes.Components.ContentBlocks.FullWidthButtonBlock;
        return <FullWidthButtonBlock key={i} block={c} />;
      default:
        throw new Error(`Unknown content block ${component.__component}`);
    }
  };

  return (
    <div className="grid gap-20 sm:gap-30">
      {contentBlocks.map((c, i) => getComponent(c, i))}
    </div>
  );
};

export { ContentBuilder };
