import { APITypes } from "@lib/api.types";
import { Block, Image, SanitizeString } from "components";
import { useEffect, useState } from "react";

interface CaseNavigationBlockProps {
  block: APITypes.Components.ContentBlocks.CaseNavigationBlock;
}

const CaseNavigationBlock = ({ block }: CaseNavigationBlockProps) => {
  const [activeSolution, setActiveSolution] = useState("");

  useEffect(() => {
    const solutionBlocks: HTMLElement[] = Array.from(
      document.querySelectorAll("[data-solution-block]")
    );

    const watch = () => {
      let first = solutionBlocks[0];

      solutionBlocks.forEach((solutionBlock) => {
        if (solutionBlock.getBoundingClientRect().top <= 87) {
          first = solutionBlock;
        }
      });

      const slug = first.dataset.solutionBlock;
      if (slug && activeSolution !== slug) {
        setActiveSolution(slug);
      }

      requestAnimationFrame(watch);
    };

    requestAnimationFrame(watch);
  }, [activeSolution, block]);

  return (
    <Block>
      <div className="relative | flex items-start gap-8 lg:gap-12 xl:gap-16 ">
        <div className="sticky top-20 | flex-grow flex-shrink pt-8 lg:pt-12 hidden md:block">
          <div className="grid gap-4 lg:gap-6">
            {block.text_with_image.map((solution, index) => (
              <a
                key={index}
                className={`
                flex items-center
                font-bold lg:text-2xl
                ${activeSolution === solution.title ? "activeCaseLink" : ""}
                hover:opacity-50
                transition-all
              `}
                href={`#solution-${solution.title}`}
              >
                <span>{solution.title}</span>
              </a>
            ))}
          </div>
        </div>
        <div className="grid gap-8 lg:gap-12 xl:gap-16 md:w-3/5  pt-4 lg:pt-8">
          {block.text_with_image.map((solution) => {
            return (
              <div key={solution.id} className="flex flex-col gap-4">
                <div
                  className="-translate-y-20 lg:-translate-y-28"
                  id={`solution-${solution.title}`}
                  data-solution-block={solution.title}
                ></div>
                {solution.subtitle != null ? (
                  <div className="text-3xl font-bold">
                    <SanitizeString text={solution.subtitle.toUpperCase()} />
                  </div>
                ) : (
                  <div className="block md:hidden md:text-3xl font-bold">
                    <SanitizeString text={solution.title} />
                  </div>
                )}
                <div className="text-base md:text-2xl font-light">
                  <SanitizeString text={solution.text} />
                </div>
                {solution.background_image.data != null && (
                  <div>
                    <Image
                      className={`rounded-lg w-32 `}
                      image={solution.background_image.data.attributes}
                      alt={solution.background_image.data.attributes.name}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Block>
  );
};

export default CaseNavigationBlock;
