import { APITypes } from "@lib/api.types";
import { Block, Image } from "components";

interface SocialButtonsBlockProps {
  block: APITypes.Components.ContentBlocks.SocialButtons;
}

const SocialButtonsBlock = ({ block }: SocialButtonsBlockProps) => {
  const classes = () =>
    [
      "flex",
      "gap-8",
      "items-center",
      "grid-cols-2",
      "justify-items-left",
      "rounded-2xl",
      "p-8",
      "font-extrabold",
      " text-2xl",
      "sm:text-3xl",
      "sm:text-left",
      "text-black",
      "h-32",
    ]
      .filter(Boolean)
      .join(" ");

  return (
    <Block>
      <div
        className={`grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-4 lg:gap-4 md:mt-10`}
      >
        {block.social_buttons.map((button, i) => {
          return (
            <a
              key={i}
              href={button.url}
              target="_blank"
              rel="noreferrer"
              className="grid"
            >
              <div
                style={{ backgroundColor: button.hexColor }}
                className={classes()}
              >
                <div>
                  <Image
                    image={button.icon.data.attributes}
                    alt={button.icon.data.attributes.name}
                  />
                </div>
                <div>
                  <div>{button.title.toUpperCase()}</div>
                  <div className="text-xs font-normal">{button.subTitle}</div>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </Block>
  );
};

export default SocialButtonsBlock;
