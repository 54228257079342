import { APITypes } from "@lib/api.types";
import { Block, Image, SectionSeparator, SectionTitle } from "components";
import SingleButtonBlock from "./SingleButtonBlock";

interface ITextWithImageBlockProps {
  block: APITypes.Components.ContentBlocks.TextWithImage;
}

const TextWithImageBlock = ({ block }: ITextWithImageBlockProps) => {
  return (
    <div>
      <SectionSeparator />
      <div className="text-black text-center bg-white textImageBlock m-[-40px]">
        <div
          style={{
            position: "absolute",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Block width="sm">
            <div className="grid grid-cols-1 pt-16 sm:pt-24 md:pt-32 lg:pt-40 xl:pt-40">
              <div className="grid">
                <SectionTitle className="text-3xl inline-block sm:text-5xl">
                  {block.title.toUpperCase()}
                </SectionTitle>
              </div>
              <div className="grid max-w-3xl m-auto text-center text-base font-light pt-[200px] sm:pt-72 md:pt-96 lg:pt-64 xl:pt-96 pr-4 pl-4">
                {block.text}
              </div>
              <div className="grid pt-8">
                <SingleButtonBlock block={block.button} />
              </div>
            </div>
          </Block>
        </div>
        <div className="h-16 lg:hidden"></div>
        <Image
          className=""
          image={{
            ...block.background_image.data.attributes,
          }}
          style={{
            display: "block",
            width: "100%",
            maxWidth: "1260px",
            maxHeight: "959px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          alt={block.title}
        />
        <div className="h-96 sm:h-60 md:h-54 lg:hidden" />
      </div>
    </div>
  );
};

export default TextWithImageBlock;
