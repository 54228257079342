import { APITypes } from "@lib/api.types";
import {
  Block,
  ButtonWrapper,
  FigmaTrainingTeaser,
  TeaserGrid,
  Image,
} from "components";

interface FeaturedFigmaTrainingBlockProps {
  block: APITypes.Components.ContentBlocks.FeaturedFigmaTrainings;
}

const FeaturedFigmaTrainingBlock = ({
  block,
}: FeaturedFigmaTrainingBlockProps) => {
  const bottomCardData = block.trainings.data.find(
    (data) => data.attributes.isBottomCard
  );

  return (
    <Block>
      <div className="grid justify-items-center gap-4">
        <TeaserGrid gap="sm" columns={2}>
          {block.trainings.data
            .sort((a, b) => a.id - b.id)
            .map(
              (data, i) =>
                !data.attributes.isBottomCard && (
                  <FigmaTrainingTeaser key={i} data={data.attributes} />
                )
            )}
        </TeaserGrid>
        {bottomCardData && (
          <FigmaTrainingBottomCard data={bottomCardData.attributes} />
        )}
      </div>
    </Block>
  );
};

export default FeaturedFigmaTrainingBlock;

interface FigmaTrainingBottomCardProps {
  data: APITypes.Collections.FigmaTraining;
}

const FigmaTrainingBottomCard = ({ data }: FigmaTrainingBottomCardProps) => {
  const { image, title, description, list, button } = data;

  return (
    <div
      className={`rounded-2xl bg-greyCold px-[40px] py-[40px] items-center w-full flex flex-col md:flex-row gap-6 md:gap-20`}
    >
      <div className="md:w-[175px] md:h-[240px] relative">
        <Image
          className="relative md:absolute z-40"
          image={image.data.attributes}
          alt={image.data.attributes.name}
        />
      </div>
      <div className="pt-[16px] flex flex-col items-center md:items-start text-center md:text-left">
        <div className="font-heroTitle text-[24px] md:text-[32px] font-extrabold leading-[120%]">
          {title.toUpperCase()}
        </div>
        <div className="text-[16px] font-normal leading-[150%]">
          {description}
        </div>
        <div className="pt-[16px] flex justify-center w-full md:w-auto">
          <ButtonWrapper
            button={button}
            size="small"
            fill={true}
            fullWidth={true}
          />
        </div>
      </div>
    </div>
  );
};
